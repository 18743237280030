import React from 'react'
import { graphql, withPrefix, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import InViewMonitor from 'react-inview-monitor'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SEO from '../components/seo'

import * as Layout from '../layouts/layout'
import * as FadeIn from '../components/fadeIn'

const Concept = ({ lang }) => (
  <>
    <SEO title="Concept" />
    <Hero className="hero">
      <HeroHeader>
        <FadeIn.Left margin="0%">
          <h1>
            {lang === 'ja' && '芙蓉苑のこだわり'}
            {lang === 'en' &&
              'I want to convey the true taste of authentic Sichuan cuisine'}
            {lang === 'zh' && '芙蓉苑的观念'}
          </h1>
        </FadeIn.Left>
        <FadeIn.Left margin="0%">
          <p>
            {lang === 'ja' &&
              '正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。'}
            {lang === 'en' &&
              'I want to convey the true taste of authentic Sichuan cuisine'}
            {lang === 'zh' &&
              '我们向您保证，会把地地道道的，美味的“正宗的四川料理”和亲切的“笑容”送给客人。'}
          </p>
        </FadeIn.Left>
      </HeroHeader>
    </Hero>
    <Grid>
      <GridBodyAbove>
        <GridBodyAboveTxt>
          <GridBodyAboveTxtBox>
            <FadeIn.Left>
              <h3>
                {lang === 'ja' &&
                  '正真正銘、本物の四川料理の美味しさを伝えたい'}
                {lang === 'en' &&
                  'I want to convey the true taste of authentic Sichuan cuisine'}
                {lang === 'zh' && '想传递地地道道，正宗的，美味的四川料理。'}
              </h3>
            </FadeIn.Left>
            <FadeIn.Left>
              <p>
                {lang === 'ja' &&
                  'トライハードダイニングは、TryHard Groupの一員として、四川料理の「文化」を大切に、お客様に「笑顔」を添えて「正真正銘、本物の四川料理の美味しさをお客様に提供する」をテーマに本場四川から特級料理人を招聘し、本格四川料理店を現在8店舗展開しております。'}
                {lang === 'en' &&
                  'Sichuan cuisine, a food culture with a history of over 2300 years, is one of the four major Chinese dishes. The food culture of the ancient tombs that appear in "Haoyang Kunishi" written in 316 BC, is said to be "The name surpasses Sanuki Sanri and the taste overwhelms the province of Gangnam".'}
                {lang === 'zh' &&
                  'TryHard餐厅，作为TryHard Group的一员，重视四川料理的“文化”，向客人提供亲切的“笑容”，以“提供给客人地地道道，美味的，正宗的四川料理”为主题，从四川聘请了四川正宗的特级厨师，现在有5家正宗的四川料理店。'}
              </p>
            </FadeIn.Left>
          </GridBodyAboveTxtBox>
        </GridBodyAboveTxt>
        <GridBodyAboveImg>
          <img src={withPrefix('/img/concept-concept.jpg')} alt="hero" />
          <InViewMonitor
            childPropsInView={{ isActive: true }}
            intoViewMargin="50%"
          >
            <GridBodyAboveImgWrapper />
          </InViewMonitor>
        </GridBodyAboveImg>
      </GridBodyAbove>
      <GridBodyBelow>
        <GridBodyBelowBox>
          <GridBodyBelowTxt>
            <FadeIn.Right>
              <InViewMonitor childPropsInView={{ isActive: true }}>
                <GridBodyBelowH2>CONCEPT</GridBodyBelowH2>
              </InViewMonitor>
            </FadeIn.Right>
            <FadeIn.Right>
              <h3>
                {lang === 'ja' && '芙蓉苑のこだわり'}
                {lang === 'en' && 'Perseverance'}
                {lang === 'zh' && '芙蓉苑的观念'}
              </h3>
            </FadeIn.Right>

            <FadeIn.Right>
              <p>
                {lang === 'ja' &&
                  '芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。'}
                {lang === 'en' &&
                  'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                {lang === 'zh' &&
                  '芙蓉苑重视地道的四川饮食文化，我们把真正的四川菜味道传递给日本作为我们的使命。我们向您保证，会把地地道道的，美味的“正宗的四川料理”和亲切的“笑容”送给客人。'}
              </p>
            </FadeIn.Right>
          </GridBodyBelowTxt>
        </GridBodyBelowBox>
      </GridBodyBelow>
    </Grid>
    <Layout.Section style={{ paddingBottom: '0' }}>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h3>
              {lang === 'ja' && '本場・四川の一流料理人が腕を振るう芙蓉苑'}
              {lang === 'en' &&
                'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
              {lang === 'zh' && '芙蓉苑是地道的四川一流厨师大显身手的地方'}
            </h3>
          </FadeIn.Left>

          <FadeIn.Left>
            <p>
              {lang === 'ja' &&
                '芙蓉苑のシェフ達全員が、四川省出身の長年の経験と実績を持つベテラン料理人。シェフひとりに、ひとつの専門料理を持ち、その手腕は四川でも最高峰の味覚です。'}
              {lang === 'en' &&
                'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
              {lang === 'zh' &&
                '芙蓉苑的厨师们，都是来自四川省，并且都是经验丰富的老厨师。一名主厨，拥有一道自己的专门料理，其水平在四川也是最高峰的.'}
            </p>
          </FadeIn.Left>
        </Header>
        <CookRow>
          <CookCol>
            <FadeIn.Up>
              <img src={withPrefix('/img/concept-ryourichou.jpg')} alt="" />
            </FadeIn.Up>
          </CookCol>
        </CookRow>
        <SubHeader>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '特級料理人を招聘'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '招聘特级大厨'}
            </h2>
          </FadeIn.Left>
        </SubHeader>
        <TxtBox>
          {lang === 'ja' &&
            '当店では調味料をはじめ食材も現地からの直輸入にこだわっています。長年の経験と実績を持つ特級料理人をその食文化のルーツである四川省の三つの地域「成都」「重慶」「自貢」から招聘。本場の食材と料理人が、渾身の一皿で四川料理の魅力をお伝えします。芙蓉苑は四川料理の「文化」を大切に、お客様に「笑顔」を添えて「正真正銘、本物の四川料理の美味しさ」をお届け致します。'}
          {lang === 'en' && 'Fuyo En'}
          {lang === 'zh' &&
            '本店以调味料为首的食材从当地直接进口。由饮食文化根源的四川省的三个地区“成都”、“重庆”、“自贡”中聘请6名具有多年经验和实力的特级厨师。地道的食材和厨师，用尽全力做好一盘美食来传达四川菜的魅力。芙蓉苑珍惜四川菜的“文化”，给客人送上亲切的“笑容”和“地地道道的、真正的四川料理的美味”。'}
        </TxtBox>
      </Layout.Container>
    </Layout.Section>
    <Layout.Section>
      <Layout.Container>
        <Header>
          <FadeIn.Left>
            <h3>
              {lang === 'ja' && '食材へのこだわり'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '食材的考究'}
            </h3>
          </FadeIn.Left>

          <FadeIn.Left>
            <p>
              {lang === 'ja' &&
                '芙蓉苑は食材・調味料にいたるまで、すべて現地から直送しています。厳選された食材と調味料を使った「本場」の四川料理を味わうことができます。'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' &&
                '芙蓉苑无论是食材、调味料，都是从当地直接送达。您可以品尝到使用严选的食材和调味品制作的“地道”四川菜。'}
            </p>
          </FadeIn.Left>
        </Header>
        <ConceptRow>
          <Layout.Col tablet={4}>
            <img src={withPrefix('/img/concept-seasoning.jpg')} alt="" />
          </Layout.Col>
          <TxtCol tablet={8}>
            <TxtArea>
              <h4>
                {lang === 'ja' && '鵑城郫縣豆瓣醬'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' && '鹃城郫县豆瓣醬'}
              </h4>
              <p>
                {lang === 'ja' &&
                  '当店が使用している豆板醤は四川省成都市内の「郫県」で作られ、高級品として知られている「郫県豆板醤」（ピーシェントウバンジャン）その中でも王様と言われる「鵑城郫縣豆瓣醬」を使用しています。「鵑城郫縣豆瓣醬」は二百年以上の歴史があり、厳選された上質な素材を使い、門外不出の秘伝酵母と二百年守ってきた特殊製法で作られており、現地でも希少価値の高い最高級品の本格豆板醤です。'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' &&
                  '本店使用的豆板酱是四川省成都市内的“郫县”制作的，味道高级而被人熟知的“郫県豆板醤”(Pen Tunjunjan)，其中“鵑城郫縣豆瓣醬”被称为豆瓣酱之王。“鹃城郫縣豆瓣醬”有二百年以上的历史，严选的优质素材，使用不外传的秘制酵母和保持俩百年的特殊的制作方法，在当地具有很高的稀有价值，是最高级别的真正的豆板酱。'}
              </p>
            </TxtArea>
          </TxtCol>
        </ConceptRow>
        <ConceptRowReverse>
          <Layout.Col tablet={4}>
            <img src={withPrefix('/img/concept-pepper.jpg')} alt="" />
          </Layout.Col>
          <TxtCol tablet={8}>
            <TxtArea>
              <h4>
                {lang === 'ja' && '汉源花椒'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' && '汉源花椒'}
              </h4>
              <p>
                {lang === 'ja' &&
                  '四川料理の代表とも言われる「麻」の味は、実は花椒の「役目」です。さわやかな香りと痺れるような辛味を持つ香辛料です。『花椒』は四川料理の命と言っても過言ではありません。唐辛子の辛味と花椒の舌がしびれるほどの「麻辣」（まーらー）の感覚は、四川料理のおいしさを生み出す重要な条件です。 『花椒』中でも「汉源花椒」が最も有名です。当店の料理達人たちは「汉源花椒」まで、こだわるからこそ、おいしい料理を作り出せるのです。'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' &&
                  '作为四川菜代表的“麻”的味道，其实是花椒的“任务”。是具有清爽香味和麻痹般的辣味的香辛料。“花椒”可以说是四川菜的生命。辣椒的辣味和花椒的麻把舌头都被麻掉的“麻辣”的感觉，是产生四川菜美味的重要条件。花椒中以“汉源花椒”最有名。本店的料理达人们只有使用“汉源花椒”，才能做出美味的料理。'}
              </p>
            </TxtArea>
          </TxtCol>
        </ConceptRowReverse>
        <SubHeader>
          <FadeIn.Left>
            <h2>
              {lang === 'ja' && '渾身の一皿で四川料理の魅力をお伝えします'}
              {lang === 'en' && 'Fuyo En'}
              {lang === 'zh' && '用尽全力做一盘菜来向您传达四川菜的魅力'}
            </h2>
          </FadeIn.Left>
        </SubHeader>
        <TxtBox>
          {lang === 'ja' &&
            '当店では調味料をはじめ食材も現地からの直輸入にこだわっています。 長年の経験と実績を持つ特級料理人をその食文化のルーツである 四川省の三つの地域「成都」「重慶」「自貢」から招聘。 本場の食材と料理人が、渾身の一皿で四川料理の魅力をお伝えします。 芙蓉苑は四川料理の「文化」を大切に、お客様に「笑顔」を添えて「正真正銘、本物の四川料理の美味しさ」をお届け致します。'}
          {lang === 'en' && 'Fuyo En'}
          {lang === 'zh' &&
            '本店以调味料为首的食材从当地直接进口。由饮食文化根源的四川省的三个地区“成都”、“重庆”、“自贡”中聘请6名具有多年经验和实力的特级厨师。地道的食材和厨师，用尽全力做好一盘美食来传达四川菜的魅力。芙蓉苑珍惜四川菜的“文化”，给客人送上亲切的“笑容”和“地地道道的、真正的四川料理的美味”。'}
        </TxtBox>
        <ConceptRowReverse>
          <Layout.Col tablet={4}>
            <img src={withPrefix('/img/concept-tea.jpg')} alt="" />
          </Layout.Col>
          <TxtCol tablet={8}>
            <TxtArea>
              <h4>
                {lang === 'ja' && '四川の食文化を日本へ'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' && '四川食文化走向日本'}
              </h4>
              <p>
                {lang === 'ja' &&
                  '2300年以上の歴史を持つ食文化である「四川料理」は“四大中華料理”の一つ。紀元前316年著作の“華陽国志”に登場する古蜀の食文化で「名は巴蜀三千里を馳せ、味は江南十二州を圧倒する」と称される。“辛い”イメージの強い四川料理ですが「一菜一格、百菜百味」と冠されるその真髄は「ひとつひとつの料理に品格があり、百の料理に百の風味がある」の意で バラエティ豊かな調理法・味付けが魅力。'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' &&
                  '具有2300多年历史的饮食文化“四川料理”是“四大中餐”之一。公元前316年著作的“华阳国志”中登场的古蜀饮食文化“名驰巴蜀三千里,味压江南十二州”之称。以“辣”为印象的四川料理，被冠为“一菜一格，百菜百味”，其精髓是“每一道菜都有品格，百道菜有一百种风味”的意思，丰富的烹饪法、调味是其魅力。'}
              </p>
            </TxtArea>
          </TxtCol>
        </ConceptRowReverse>
      </Layout.Container>
    </Layout.Section>
    <NamingSection>
      <Naming>
        <Layout.Container>
          <NamingRow>
            <NamingHeader>
              <FadeIn.Left>
                <h2>
                  {lang === 'ja' && '芙蓉苑 命名の由来'}
                  {lang === 'en' && 'Fuyo En'}
                  {lang === 'zh' && '芙蓉苑 名字的由来'}
                </h2>
              </FadeIn.Left>
              <FadeIn.Left>
                <p>
                  {lang === 'ja' &&
                    '命名の由来は四川省成都市は「天府の国」と称される1000年以上の歴史から。豊かな土壌は食と文化を育む'}
                  {lang === 'en' && 'Fuyo En'}
                  {lang === 'zh' &&
                    '命名的由来来自四川省成都市被称为“天府之国”，有1000多年的历史。丰沛的土壤孕育着饮食和文化。'}
                </p>
              </FadeIn.Left>
            </NamingHeader>
            <FadeIn.Up>
              <p>
                {lang === 'ja' &&
                  '「天府の国」の原点は遡ること1000年以上、時の皇帝・孟昶は「四川省のような険しい地形では外敵に備える必要は少ない」と考え、軍備を強化する代わりに農業や養蚕業の発展に尽力し、文化人の保護に力を注ぎました。孟昶は更に、首都・成都を美しく装飾することを命じ、家臣たちは大輪の花を咲かせる「芙蓉」の花をいたることころに植えたのです。成都城はみるみる桃色の花に覆われ、そのあまりの美しさに「芙蓉城」と呼ばれるようになりました。それ以来、「芙蓉」といえば四川省であり、四川文化を象徴する花となったのです。「芙蓉苑」とは、芙蓉の園（畑）の意で、つまりは四川の畑（食材）が連想されるよう名付けました。その歴史に恥じぬよう、本場の味を追求してまいります。'}
                {lang === 'en' && 'Fuyo En'}
                {lang === 'zh' &&
                  '“天府之国”的最初可以追溯1000年以上，当时的皇帝·孟昶考虑到“四川省这样的险峻的地形不需要做太多对抗外敌的准备”，从而代替强化军备，开始发展农业和养蚕业，还有保护文化人。孟昶，在首都成都进行装饰，家臣们到处种了大朵开花的“芙蓉”花，成都城放眼看去都是桃色的花，所以有了“芙蓉城”之称。从那以后，说到“芙蓉”，就是四川省，成为了象征四川文化的花。“芙蓉苑”是芙蓉的园(田)的意思也就是让人联想到四川的田地(食材)而命名的。为了不辱没它的历史，我们一直在追求正宗的味道.'}
              </p>
            </FadeIn.Up>
          </NamingRow>
        </Layout.Container>
      </Naming>
    </NamingSection>
  </>
)

export const pageQuery = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          title
          date(formatString: "YYYY/MM/DD")
          slug
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Concept)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'
// const darkBrown = '#42210B'

const Hero = styled.div`
  position: relative;
  height: 66.6666666666vw;
  max-height: 600px;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/concept-hero.jpg) center/cover;
  animation: 15s ${Layout.ZoomBg} infinite;
`

const HeroHeader = styled.header`
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 60px);
  @media (min-width: 1025px) {
    max-width: 450px;
    left: 30%;
  }
  h1 {
    line-height: 2;
    letter-spacing: 10px;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.4;
    letter-spacing: 5px;
  }
`

const Grid = styled.section`
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: 0.5fr 4fr 3fr 0.5fr;
    grid-template-rows: 2fr 2fr 0.25fr;
  }
`

const GridBodyAbove = styled.div`
  background: ${lightBeige};
  @media (min-width: 1025px) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 3fr;
    z-index: 2;
  }
`

const GridBodyAboveTxt = styled.div`
  padding: 30px;
  color: ${brown};
  @media (min-width: 1025px) {
    padding: 0;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 2fr 3fr 1fr;
  }
`

const GridBodyAboveTxtBox = styled.div`
  h3 {
    color: ${brown};
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    transform: translateX(150px);
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const GridBodyAboveImg = styled.div`
  position: relative;
  @media (min-width: 1025px) {
    grid-row: 2 / 3;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
`

const GridBodyAboveImgWrapper = styled.div`
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 33.33333%,
    ${pink} 33.33333%,
    ${pink} 66.66666%,
    ${lightBeige} 66.66666%
  );
  background-position: 100%;
  background-size: 300%;
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${Layout.SlideBg} 1s ease-out 1 both;
    `}
`

const GridBodyBelow = styled.div`
  padding: 30px;
  @media (min-width: 1025px) {
    padding: 0;
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }
`

const GridBodyBelowBox = styled.div`
  border: 4px solid ${brown};
  @media (min-width: 1025px) {
    height: 100%;
    display: grid;
    grid-template-columns: 7.5fr 4fr 0.5fr;
    grid-template-rows: 1fr 4fr 1fr;
  }
`

const GridBodyBelowTxt = styled.div`
  padding: 30px;
  color: ${brown};
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    line-height: 2;
    @media (min-width: 1025px) {
      margin-bottom: 30px;
    }
  }
  @media (min-width: 1025px) {
    padding: 15px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    h2 {
      font-size: 52px;
      &::after {
        height: 36px;
        margin-top: -36px;
        margin-bottom: 15px;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
    }
  }
`

const GridBodyBelowH2 = styled.h2`
  font-family: 'Crimson Text', serif;
  font-style: italic;
  font-size: 36px;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 23px;
    background: ${beige};
    margin-top: -23px;
    margin-bottom: 15px;
    z-index: -1;
    transition: width 1.5s ease;
  }
  @media (min-width: 1025px) {
    font-size: 52px;
    &::after {
      height: 36px;
      margin-top: -36px;
      margin-bottom: 15px;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        width: 100%;
      }
    `}
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Button = styled.a`
position:relative;
z-index: 1;
  p {
    background: linear-gradient(90deg, ${pink} 0%, ${beige} 33.3333333333%);
    background-size:300%;
    background-position: 0;
    position: relative;
    display: block;
    padding: 20px 30px;
    margin-top:0;
    margin-bottom: 0;
    color: #fff;
    transition: transform .5s ease, box-shadow .5s ease;

    &:before {
      font-size: 18px;
      content: '→';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      animation 1s ${Layout.SlideBg} 1;
      transform: translate(10px,10px);
    }
  }
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${brown};
    top: 10px;
    left: 10px;
    z-index: -1;
}
`

const Header = styled.div`
  color: ${brown};
  padding: 0 0 30px;
  h3 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    padding: 0 0 50px;
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const SubHeader = styled.header`
  position: relative;
  margin-top: 60px;
  h2 {
    color: ${brown};
    margin-left: 20px;
  }
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 5px;
    min-height: 45px;
    height: 120%;
    background: ${brown};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  @media (min-width: 415px) {
    margin-top: 100px;
    margin-bottom: 20px;
    h2 {
      margin-left: 30px;
    }
    &::before {
      height: 64px;
      width: 10px;
    }
  }
`

const ConceptRow = styled(Layout.Row)`
  margin-bottom: 30px;
  @media (min-width: 415px) {
    margin-bottom: 50px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`

const ConceptRowReverse = styled(ConceptRow)`
  flex-direction: row-reverse;
`

const TxtBox = styled.div`
  padding: 30px 0;
  color: ${brown};
`

const TxtArea = styled.div`
  padding: 30px;
  color: ${brown};
  background: ${lightBeige};
  height: 100%;
  h4 {
    margin-bottom: 15px;
  }
  @media (min-width: 415px) {
    h4 {
      margin-bottom: 30px;
    }
  }
`

const TxtCol = styled(Layout.Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CookRow = styled(Layout.Row)`
  justify-content: center;
`

const CookCol = styled(Layout.Col)`
  max-width: 1000px;
`

const Naming = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(
      -30deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    url(/img/concept-naming.jpg) center/cover;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 415px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  p {
    color: ${brown};
    @media (min-width: 415px) {
      line-height: 2;
      font-size: 18px;
    }
  }
`

const NamingSection = styled.section``

const NamingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const NamingHeader = styled.header`
  color: ${brown};
  margin-bottom: 30px;
  h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    margin-bottom: 50px;
    h2 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`
